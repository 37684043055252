<template>
    <v-container id="o-mnie" tag="section">
        <base-subheading class="text-center">O mnie</base-subheading>
              <p class="text-center" data-aos="fade-up" >
                Jestem Self-publisherką. Tworzę dla kobiet, które chcą się oderwać od rzeczywistości, by zatonąć w niesamowitym i magicznym świecie romansów paranormalnych. Kobiet chcących odprężyć się przy lekkiej lekturze, wywołującej zarówno rumieńce, jak i śmiech. Kobiet lubiących zagłębić się w odważne historie, napisane przystępnym i czasem mało kwiecistym językiem.
                </br></br> Mam zamiłowanie do klimatu paranormal, fantasy i Sci-fi, zarówno jeśli chodzi o książki, jak i filmy oraz seriale. Uwielbiam taniec i muzykę. Ale najbardziej kocham czytać lub pisać o tym, jak twardy, dominujący i seksowny facet alfa upada na kolana dla tej jedynej. 
                </br></br> Serdecznie zapraszam na moją stronę na <a href="https://www.facebook.com/NatashaLucas.Books">Facebooku</a> oraz
                <a href="https://www.instagram.com/natashalucas.books/">Instagramie</a>.
              </p>  
            <v-img data-aos="zoom-in" max-height="30%" max-width="30%"  :src="require('@/assets/about-bg.png')"></v-img>     
     </v-container>
</template>

<script>
export default {
	name: "About",

	metaInfo() {
		return {
			meta: [
				{
					name: "description",
					content:
						"Strona z informacjami o autorce Natasha Lucas. Jestem Self-publisherką. Tworzę dla kobiet, które chcą się oderwać od rzeczywistości, by zatonąć w niesamowitym i magicznym świecie romansów paranormalnych. Kobiet chcących odprężyć się przy lekkiej lekturze, wywołującej zarówno rumieńce, jak i śmiech. Kobiet lubiących zagłębić się w odważne historie, napisane przystępnym i czasem mało kwiecistym językiem.",
				},
				{ property: "og:title", content: "O Mnie | Natasha Lucas" },
				{
					property: "og:description",
					content:
						"Strona z informacjami o autorce Natasha Lucas. Jestem Self-publisherką. Tworzę dla kobiet, które chcą się oderwać od rzeczywistości, by zatonąć w niesamowitym i magicznym świecie romansów paranormalnych. Kobiet chcących odprężyć się przy lekkiej lekturze, wywołującej zarówno rumieńce, jak i śmiech. Kobiet lubiących zagłębić się w odważne historie, napisane przystępnym i czasem mało kwiecistym językiem.",
				},
				{ property: "og:url", content: "https://www.natashalucas.pl/o-mnie" },
				{ property: "og:image", content: "https://www.natashalucas.pl/img/icons/natashaLucas.png" },
				{ name: "robots", content: "index,follow" },
			],
		};
	},
	beforeRouteEnter(to, from, next) {
		to.meta.title = "O Mnie";
		document.title = "Natasha Lucas - O Mnie";
		next();
	},
};
</script>
<style lang="scss"  scoped>
.text-center {
	font: 22px Raleway;
}
</style>